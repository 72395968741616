<template>
  <div>
    <KTCard title="List">
      <template v-slot:toolbar>
        <b-button @click="onOpenModal" variant="primary" size="sm"
          ><i class="fa fa-plus"></i> Add Trainee User</b-button
        >
      </template>

      <template v-slot:body>
        <b-row v-if="!!tableSettings">
          <b-col cols="6">
            <b-form-group class="m-2" label-size="sm">
              <template v-if="tableSettings.refresh">
                <b-button size="sm" class="mr-2">
                  <i class="fa fa-sync"></i> Refresh
                </b-button>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="m-2"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
            >
              <template v-if="tableSettings.filter">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="filter"
          striped
          bordered
          show-empty
          hover
          :items="traineeUserList"
          :fields="fields"
        >
          <template v-slot:cell(actions)="row">
            <b-button-group size="sm">
              <b-button
                @click="onPermission(row.item)"
                variant="primary"
                size="sm"
              >
                Permissions
              </b-button>
              <b-button @click="onRemove(row.item)" variant="danger" size="sm">
                Remove
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
      </template>
    </KTCard>

    <trainee-user-pre-registration-modal></trainee-user-pre-registration-modal>
    <trainee-permission-modal></trainee-permission-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import {
  GET_TRAINEE_USER_LIST,
  DELETE_TRAINEE_USER,
} from "@/core/services/store/modules/clientadmin/trainee.module";

import { validationMixin } from "vuelidate";

import TraineePermissionModal from "@/view/pages/clientadmin/traineeuser/modals/TraineePermissionModal";
import TraineeUserPreRegistrationModal from "@/view/pages/clientadmin/traineeuser/modals/TraineeUserPreRegistrationModal";
import SystemHelper from "@/core/services/systemhelper.service";

export default {
  mixins: [validationMixin],
  components: { TraineeUserPreRegistrationModal, TraineePermissionModal },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Client Admin" },
      { title: "Manage Permission" },
    ]);

    this.$store.dispatch(GET_TRAINEE_USER_LIST, this.defaultPayload);
  },
  computed: {
    tableSettings() {
      const list = this.$store.state.clientadmin_trainee.traineeUserList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
        pagination: true,
      };
    },
    traineeUserList() {
      const list = this.$store.state.clientadmin_trainee.traineeUserList;
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      modalId: "TraineeUserPreRegistrationModal",
      selectedTrainee: null,
      filter: "",
      currentPage: 1,
      fields: [
        {
          key: "trainee_user_id",
          label: "Trainee User ID",
          sortable: true,
        },
        {
          key: "username",
          label: "Username",
          sortable: false,
        },
        {
          key: "email",
          label: "Email",
          sortable: false,
        },
        {
          key: "created_at",
          label: "Created On",
          sortable: false,
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-right",
        },
      ],
    };
  },

  methods: {
    onOpenModal() {
      this.openModal("modalAddTraineeUser");
    },
    onPermission(trainee) {
      // assign selected trainee
      this.selectedTrainee = trainee;

      // open permission modal
      this.openModal("modalTraineePermission");
    },
    onRemove(trainee) {
      // assign selected trainee
      this.selectedTrainee = trainee;

      let payload = {
        trainee_user_id: this.selectedTrainee.trainee_user_id,
      };

      // open permission modal
      SystemHelper.confirmationDialogHandler(
        { title: "Confirmation", html: "Remove User?" },
        { action: DELETE_TRAINEE_USER, payload: payload },
        [{ action: GET_TRAINEE_USER_LIST, payload: this.defaultPayload }],
        null
      );
    },
  },
};
</script>
